.App {
  margin: 0; 
  padding: 0;
  box-sizing: border-box;
}

main > div {
  padding: 0 15%;
  background-color: white;
}

@media (max-width: 767px) {
  main > div {
    padding: 0 25px;
  }
}

* {
  scroll-behavior: smooth;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-out;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}